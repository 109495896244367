import { useColorModeValue, Text, Stack, Box, Tooltip, IconButton, Icon } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { IoMdHelpCircleOutline } from "react-icons/io";

interface Props {
  title: string;
  subtitle?: string;
  helpText?: string;
  helpUrl?: string;
  className?: string;
}

export const SimpleMetric: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  title,
  subtitle,
  helpText,
  helpUrl,
  className,
  children,
}) => {
  const titleColor = useColorModeValue("charli.primaryBlue", "gray.500");
  const subtitleColor = useColorModeValue("gray.400", "gray.200");
  const helpIconColor = useColorModeValue("gray.400", "gray.400");

  const handleHelpClick = () => {
    if (helpUrl) {
      window.open(helpUrl, "_blank");
    }
  };

  return (
    <Stack spacing="0" width="100%" height="100%" justifyContent={"space-between"}>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Text isTruncated fontSize="sm" fontWeight="semibold" color={titleColor} textAlign="center">
            {title}
          </Text>
          {helpText && (
            <Tooltip label={helpText} maxWidth={"12rem"} className={`${className}-tooltip`}>
              <IconButton
                icon={
                  <Icon
                    className={className}
                    as={IoMdHelpCircleOutline}
                    boxSize={"1rem"}
                    _hover={{ opacity: helpUrl ? 0.8 : 1 }}
                    cursor={helpUrl ? "pointer" : "default"}
                    onClick={handleHelpClick}
                  />
                }
                aria-label="Help"
                variant="ghost"
                size="sm"
                height={"0"}
                color={helpIconColor}
              />
            </Tooltip>
          )}
        </Stack>
        <Text isTruncated mt="0!important" fontSize="10px" fontWeight="light" color={subtitleColor} textAlign="left">
          {subtitle}
        </Text>
      </Stack>
      <Box>{children}</Box>
    </Stack>
  );
};
