import { reset as analyticsReset } from "api/analytics";
import { connect as connectWebsocket, disconnect as disconnectWebsocket } from "state/websocket/operations";
import { downloadNotifications } from "state/notification/operations";
import { v4 as uuid } from "uuid";
import type { Middleware } from "redux";
import { loginActions, logoutActions } from "./actionGroups";
import { googleLogout } from "@react-oauth/google";
import { downloadProjectConfigurations } from "state/config/operations";
import { downloadSubscriptionDetails } from "state/subscription/operations";
import { actions as sessionActions } from "state/session/reducer";
import { actions as billingActions } from "state/billing/reducer";
import { actions as conversationActions } from "state/conversation/reducer";
import { actions as websocketActions } from "state/websocket/reducer";
import { actions as selectionActions } from "state/selection/reducer";
import { actions as commandActions } from "state/command/reducer";
import { actions as flagsActions } from "state/flags/reducer";
import { actions as entitlementsActions } from "state/entitlements/reducer";
import { actions as navigationActions } from "state/navigation/reducer";
import { actions as notificationActions } from "state/notification/reducer";
import { actions as collectionActions } from "state/collection/reducer";
import { actions as userPreferenceActions } from "state/userPreference/reducer";
import { actions as systemPreferenceActions } from "state/systemPreference/reducer";
import { actions as homeActions } from "state/home/reducer";
import { actions as shareHistoryActions } from "state/shareHistory/reducer";
import { actions as integrationsActions } from "state/integrations/reducer";
import { actions as contentActions } from "state/content/reducer";
import { actions as configuredWorkflowActions } from "state/configuredWorkflow/reducer";
import { actions as workflowActions } from "state/workflow/reducer";
import { actions as checkpointsActions } from "state/checkpoints/reducer";
import { actions as usersActions } from "state/users/reducer";
import { actions as meetingSyncActions } from "state/meetings/reducer";
import { actions as taskActions } from "state/task/reducer";
import { actions as billingPlansActions } from "state/billingPlans/reducer";
import { actions as organizationActions } from "state/organization/reducer";
import { actions as memberOrganizationsActions } from "state/memberOrganizations/reducer";
import { actions as reportTemplatesActions } from "state/reportTemplates/reducer";
import { actions as entityConfigurationsActions } from "state/entityConfigurations/reducer";
import { actions as memorizedClarificationConfigurationsActions } from "state/memorizedClarificationConfig/reducer";
import { actions as configActions } from "state/config/reducer";
import { actions as workflowStatsActions } from "state/workflowStats/reducer";
import { actions as informationExtractionConfigActions } from "state/informationExtractionConfig/reducer";
import { actions as organizationsPreferenceActions } from "state/organizationPreference/reducer";
import { actions as charliActivitiesActions } from "state/charliActivities/reducer";
import { actions as suggestedQuestionsActions } from "state/suggestedQuestions/reducer";
import { actions as newsInformationActions } from "state/newsInformation/reducer";
import { actions as usageActions } from "state/usage/reducer";
import { actions as subscriptionsActions } from "state/subscription/reducer";
import { actions as financialMarketActions } from "state/financialMarket/reducer";
import { actions as contextCoordinatesActions } from "state/contextCoordinates/reducer";
import { actions as collectionMetadata } from "state/collectionMetadata/reducer";
import { actions as milestonesActions } from "state/milestones/reducer";
import { downloadInitialCollections } from "state/collection/operations";

let notificationInterval: NodeJS.Timeout | undefined;
const allFlushActions = [
  sessionActions.flush,
  billingActions.flush,
  conversationActions.flush,
  websocketActions.flush,
  selectionActions.flush,
  commandActions.flush,
  flagsActions.flush,
  entitlementsActions.flush,
  navigationActions.flush,
  notificationActions.flush,
  collectionActions.flush,
  userPreferenceActions.flush,
  systemPreferenceActions.flush,
  homeActions.flush,
  shareHistoryActions.flush,
  integrationsActions.flush,
  contentActions.flush,
  configuredWorkflowActions.flush,
  workflowActions.flush,
  checkpointsActions.flush,
  usersActions.flush,
  meetingSyncActions.flush,
  taskActions.flush,
  billingPlansActions.flush,
  organizationActions.flush,
  memberOrganizationsActions.flush,
  reportTemplatesActions.flush,
  entityConfigurationsActions.flush,
  memorizedClarificationConfigurationsActions.flush,
  configActions.flush,
  workflowStatsActions.flush,
  informationExtractionConfigActions.flush,
  organizationsPreferenceActions.flush,
  charliActivitiesActions.flush,
  suggestedQuestionsActions.flush,
  newsInformationActions.flush,
  usageActions.flush,
  subscriptionsActions.flush,
  financialMarketActions.flush,
  contextCoordinatesActions.flush,
  collectionMetadata.flush,
  milestonesActions.flush,
];

export const sessionManagerMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  if (loginActions.includes(action.type)) {
    store.dispatch(downloadProjectConfigurations() as never);
    store.dispatch(connectWebsocket(uuid()) as never);
    store.dispatch(downloadSubscriptionDetails() as never);
    store.dispatch(downloadInitialCollections({ homeLimit: 15, portfolioLimit: 50 }) as never);

    // refresh notifications every 5 minutes
    notificationInterval = setInterval(() => {
      store.dispatch(downloadNotifications() as never);
    }, 300000);
  }

  if (logoutActions.includes(action.type)) {
    // Workaround for redux-persist not clearing the store of nested reducers after logout
    allFlushActions.forEach((flushAction) => store.dispatch(flushAction() as never));

    store.dispatch(disconnectWebsocket() as never);
    analyticsReset();

    // Stop polling for notifications
    if (notificationInterval) {
      clearInterval(notificationInterval);
    }

    try {
      googleLogout();
    } catch (err) {
      console.warn("Error when logging out of Google session");
      console.warn(err);
    }
  }
};
