import React, { useRef } from "react";
import { PopoverTrigger, Popover, PopoverBody, PopoverContent, Portal, PopoverHeader, useColorModeValue } from "@chakra-ui/react";
import { ProjectAnalysisPopoverContent } from "./ProjectAnalysisPopoverContent";
import { ProjectAnalysisPopoverHeader } from "./ProjectAnalysisPopoverHeader";

interface ProjectAnalysisPopoverProps {
  children: React.ReactNode;
  projectId?: string;
  openProjectButton?: boolean;
}

export const ProjectAnalysisPopover: React.FC<ProjectAnalysisPopoverProps> = ({ children, projectId, openProjectButton }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverBgColor = useColorModeValue("white", "gray.700");
  const tabPanelBgColor = useColorModeValue("gray.700", "gray.900");

  return (
    <Popover trigger="hover" isLazy>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent width="100%" boxShadow={"lg"} maxWidth={"50rem"} className="ch-project-analysis-popover">
          <PopoverHeader bgColor={popoverBgColor} fontSize="md" borderTopRadius={"md"}>
            <ProjectAnalysisPopoverHeader projectId={projectId} openProjectButton />
          </PopoverHeader>
          <PopoverBody p="0" width="100%" ref={containerRef} backgroundColor={tabPanelBgColor} borderBottomRadius={"md"}>
            <ProjectAnalysisPopoverContent projectId={projectId} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
